import React from "react";
import { PageHeader } from '../components/PageHeader'

export function Projects() {
  return (
    <div className="page">
      <PageHeader title={'Projects'}/>
      <div className="page-content">
      <div className="project-item">
          <span>Omnifood</span>
          <p>Food delivery site using pure CSS/SCSS and HTML.</p>
          <a target="_blank" rel="noreferrer"  href="https://gb-omnifood.netlify.app/">https://gb-omnifood.netlify.app/</a>
        </div>
        <div className="project-item">
          <span>Natours</span>
          <p>Nature tours site using pure CSS/SCSS and HTML.</p>
          <a target="_blank" rel="noreferrer"  href="https://gb-natours.netlify.app/">https://gb-natours.netlify.app/</a>
        </div>
        <div className="project-item">
          <span>React Chess</span>
          <p>Local multiplayer chess app using React.</p>
          <a target="_blank" rel="noreferrer" href="https://gb-react-chess.netlify.app/">https://gb-react-chess.netlify.app/</a>
        </div>
        <div className="project-item">
          <span>Labyrinth</span>
          <p>Original web game using React and NodeJS.</p>
          <a target="_blank" rel="noreferrer" href="https://gb-labyrinth-react.netlify.app">https://gb-labyrinth-react.netlify.app</a> 
        </div>
      </div>
    </div>
  );
}
