import React from "react";
import { Link } from "react-router-dom";
import portrait from "../images/updated-headshot.jpeg";

export function PageHeader({ title }) {
  let isHomepage = title === "Full Stack Developer";
  return (
    <div className="page-header">
      {!isHomepage && <Link to={`/`} className="btn-back"></Link>}
      <div className="photo">
        <img src={portrait} alt="Grayson Blanchard" />
      </div>
      <h1>Grayson Blanchard</h1>
      <h3>{title}</h3>
    </div>
  );
}
