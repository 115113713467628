import React from "react";
import { PageHeader } from '../components/PageHeader'

export function Experience() {
  return (
    <div className="page">
      <PageHeader title={'Education & Experience'}/>
      <div className="page-content">
        <p className="experience-item"><span>Howard Hughes Medical Institute</span> Web App Developer, 2022 - Present</p>
        <p className="experience-item"><span>Subscribe Pro</span> Jr. Full Stack Developer, 2020 - 2022</p>
        <p className="experience-item"><span>McDaniel College</span> BA Computer Science, 2020</p>
        {/* <p className="experience-item"><span>McDaniel College</span> Computer Science Tutor, 2019 - 2020</p> */}
        {/* <p className="experience-item"><span>Diamond Farm Stingrays Swim Team</span> Head Coach, 2017 - 2019</p> */}
      </div>
    </div>
  );
}
