import React from "react";
import { PageHeader } from "../components/PageHeader";

export function Skills() {
  return (
    <div className="page">
      <PageHeader title={"Skills"} />
      <div className="page-content">
        <div className="skills-list">
          <p>
            HTML, CSS, SCSS, JavaScript, TypeScript, React, APIs, GitHub, CLI, Node, Java, AWS, SSH,
            MySQL, SQL Server, Azure, C#
          </p>
        </div>
      </div>
    </div>
  );
}
