import React from "react";
import { Link } from "react-router-dom";
import { PageHeader } from '../components/PageHeader'
import resume from "../files/GraysonBlanchardResume.pdf";

export function Homepage() {
  return (
    <div className="page">
      <PageHeader title={'Full Stack Developer'}/>
      <div className="page-content">
        <ul className="links-list">
          <li><Link to="/experience">Education & Experience</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/skills">Skills</Link></li>
          <li><Link to={resume} target="_blank" rel="noreferrer">Resume</Link></li>
          <li><a href="https://github.com/graysonblanchard" target="_blank" rel="noreferrer">GitHub</a></li>
        </ul>
      </div>
    </div>
  );
}
